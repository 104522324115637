import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"

const ExperienceMixanalogCTA = ({ toppad = false }) => (
  <section className={classnames("call_to_action_1 bg-light pb-95 text-center", toppad && "pt-50")}>
    <div className="container px-xl-0">
      <div className="row justify-content-center">
        <div className="col-xl-8 col-lg-10" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
          <h2>Experience Mix:analog Technology</h2>
        </div>
        <div className="col-xl-7 col-lg-9 col-md-10">
          <div className="mt-20 mb-60 f-22 color-heading text-adaptive description" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300">
            There is only so much that can be conveyed with words and images. To get really impressed with what mix:analog can do, give it a shot and try it yourself with our zero
            risk free trial!
          </div>
          <div data-aos-duration="600" data-aos="fade-down" data-aos-delay="600">
            <Link to={"/"} className="mb-30 btn lg border-gray color-main">
              Back To Home
            </Link>
            <span className={"pl-25"}/>
            <a href="https://app.mixanalog.com" className="mb-30 btn lg action-1">
              Enter Studio
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ExperienceMixanalogCTA
