import React from "react"
import { graphql } from "gatsby"

import LayoutPage from "../components/layout-page"
import SEO from "../components/seo"
import ExperienceMixanalogCTA from "../components/experience-cta"
import LocalFluid from "../components/fluid"
import ImageMeta from "../components/ImageMeta"

const TechnologyPage = ({ data }) => (
  <LayoutPage>
    <SEO title={"D-POT Technology"} meta={ImageMeta}/>
    <section className="feature_9 bg-light pt-105 pb-65">
      <div className="container px-xl-0">
        <div className="row justify-content-between">
          <div className="col-xl-10">
            <h2 className="mb-25 small" data-aos-duration="600" data-aos="fade-down" data-aos-delay="0">
              Distopik D-POT Technology
            </h2>
            <div className="text-heading f-22 op-7 text-adaptive">
              <p>
                Even though we are very definitely in the digital era, analog devices are still very common in
                recording, mixing, mastering studios around the world. Why is that?
              </p>
              <p>
                It's definitely not just because they are old. Older equipment breaks more easily and is more epensive
                to maintain. Instead, it's about the sound and character of
                these devices that makes them so attractive.
              </p>
              <p>
                At Distopik, we marry the analog equipment with digital control that we developed. The digital control
                is called D-POT and uses passive design using relays as
                switches - similar to "mastering edition gear" with stepped controls. Instead of a human turning the
                knobs, a computer tells the circuit which relays to turn on
                instead.
              </p>
              <p>This unlocks many possibilities like having equipment in one room (or continent) away from the user,
                undo, presets, instant a/b comparisons and so on.</p>
            </div>
          </div>
        </div>
        <div className="mt-45 mb-70 hr bg-gray h-2" data-aos-duration="600" data-aos="fade-down" data-aos-delay="300"/>
        <div className={"mb-70"}>
          <h3>The Benefits of D-POT for automation</h3>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 mb-40" data-aos-duration="600" data-aos="fade-down" data-aos-delay="600">
            <div className="mb-20 f-14 semibold text-uppercase sp-20 title">Transparency</div>
            <div className="color-heading op-7 text-adaptive">
              Only resistors and switches are used in the signal path of the D-POT. It is a drop-in replacement for
              potentiometers and rheostats, behaves and measures as expected
              with no degradation in quality.
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-40" data-aos-duration="600" data-aos="fade-down" data-aos-delay="900">
            <div className="mb-20 f-14 semibold text-uppercase sp-20 title">Tracking accuracy</div>
            <div className="color-heading op-7 text-adaptive">
              Audio potentiometer usually have a tolerance rating of &#177;20%. Any two potentiometers may be as much as
              40% different in feel. Mastering gear uses stepped controls
              so that knobs track identically. D-POTs follow the same concept with a &#177;1% tolerance rating.
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-40" data-aos-duration="600" data-aos="fade-down" data-aos-delay="1200">
            <div className="mb-20 f-14 semibold text-uppercase sp-20 title">Tracking speed</div>
            <div className="color-heading op-7 text-adaptive">
              While it is possible to attach a servo motor to automate knobs, this negates the benefit of <em>tracking
              accuracy</em>, but also reduces the rate of change which
              makes the controls feel like they are lagging. D-POTs can change to any position in milliseconds.
            </div>
          </div>
          <div className="col-lg-3 col-md-6 mb-40" data-aos-duration="600" data-aos="fade-down" data-aos-delay="1500">
            <div className="mb-20 f-14 semibold text-uppercase sp-20 title">Intelligent design</div>
            <div className="color-heading op-7 text-adaptive">
              Due to the way we hear sound, designers use exotic types of pots to react perfectly as the knob is moved.
              In order to hit the same feel, we utilize a machine learning
              tool designing a relay network topology with &lt;1% deviation from the target.
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="showcase_8 bg-dark">
      <div className="container-fluid px-0">
        <div className="row no-gutters">
          {data.gallery.images.map(image => (
            <LocalFluid src={image} className="col-4" alt="" data-aos-duration="600" data-aos="fade-down"
                        data-aos-delay="0">
              <h5>{image.title}</h5>
            </LocalFluid>
          ))}
        </div>
      </div>
    </section>
    <section className="testimonial_2 bg-light pt-75 pb-75">
      <div className="container px-xl-0 text-center">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-8 col-md-10">
            <i className="fas fa-quote-left f-30 color-gray" data-aos-duration="600" data-aos="fade-down"
               data-aos-delay="0"/>
            <div className="mt-35 mb-45 f-22 text-adaptive" data-aos-duration="600" data-aos="fade-down"
                 data-aos-delay="300">
              The use of machine learning algorithms to optimize the digital control transfer characteristics is nothing
              short of genius! It shows the level of dedication that only
              a specialized company can execute. <span className={"text-nowrap"}>I LOVE IT!</span>
            </div>
            <img src={require("../i/jakob.jpg")} className="w-120 h-120 radius_full" alt="" data-aos-duration="600"
                 data-aos="fade-down" data-aos-delay="600"/>
            <div className="mt-20 f-18 medium" data-aos-duration="600" data-aos="fade-down" data-aos-delay="900">
              Jakob Erland
            </div>
            <div className="f-14 semibold color-heading text-uppercase sp-20" data-aos-duration="600"
                 data-aos="fade-down" data-aos-delay="900">
              Gyraf Audio
            </div>
          </div>
        </div>
      </div>
    </section>
    <ExperienceMixanalogCTA/>
  </LayoutPage>
)

export default TechnologyPage

export const query = graphql`
  query {
    gallery: contentfulGallery(name: { eq: "Technology" }) {
      images {
        title
        description
        localFile {
          childImageSharp {
            fluid(maxWidth: 4096, base64Width: 128) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
